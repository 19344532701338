import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <>
      <SEO title={"Appel d’introspection"} keywords={[`appel`]} />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader
          title="Appel d'introspection"
          header="/images/headers/14.jpg"
        />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">Premier contact</h2>
              <div class="at-page__title-divider"></div>
              <p>
                <img
                  src={"/images/call.jpg"}
                  className="size-medium alignright"
                  alt="Diane Le Blanc - Appel d'introspection"
                />
                &bull; Tu es plutôt sceptique à l'égard du coaching et des
                méthodes en développement personnel ? <br />
                &bull; Tu te demande si se sont réellement les meilleures façons
                de travailler sur toi ? <br />
                &bull; Tu aimerais me connaître un peu plus pour voir si nous
                sommes alignés ? <br />
              </p>
              <p>
                C’est pourquoi je te propose un appel d’introspection; une
                rencontre entre toi et moi, afin de savoir si nous sommes
                fait.e.s pour travailler ensemble mais également découvrir si
                c’est le bon moment pour toi.
              </p>
              <p>
                Prenons ensemble le temps de nous rencontrer{" "}
                <strong>gratuitement</strong>,{" "}
                <strong> sans limite de temps</strong> et{" "}
                <strong>sans engagement</strong>, de la façon qui te conviendra
                le plus (appel ou visio). Ce premier contact offert, d’une
                valeur de 90$, te permettra :
              </p>
              <ul>
                <li>De me parler ouvertement de tes problématiques ;</li>
                <li>De m’expliquer ce que tu as mis en place ;</li>
                <li>De comprendre pourquoi cela n’a pas fonctionné ;</li>
                <li>De découvrir ce que tu voudrais réellement changer ;</li>
                <li>Et également apprendre à me connaître.</li>
              </ul>
              <p>
                Je serais ravie que nous explorions ensemble les différentes
                possibilités pour t’accompagner et t'aider à tracer ton propre
                chemin. Ainsi, nous pourrons déterminer une nouvelle vision
                objective et sereine sur ta situation et déciderons ensemble si
                je suis la coach d’une vie dont tu as besoin pour te guider et
                t’accompagner.
              </p>
              <p>
                Sache qu’il n’y a aucune obligation et aucun engagement après
                cette 1ère prise de contact. Je demande un engagement sérieux
                pour cette séance découverte. Aussi, toute annulation doit être
                prévenue 24h à l’avance.
              </p>
              <p>A très vite pour cette belle rencontre d'introspection !</p>
            </div>
          </article>
          <section class="centered">
            <div
              data-professionalpagename="chezlosteosurrosemont"
              data-bookingwidgeturlparams="companyId=101221"
              data-language="fr"
              data-label="Prendre un rendez-vous"
              data-url="https://www.gorendezvous.com/"
              class="gorendezvous-button"
              data-buttoncolor="info"
              data-width="220px"
              data-height="55px"
            >
              <a
                href="https://www.gorendezvous.com/chezlosteosurrosemont?companyId=101221"
                target="GOrendezvous"
              >
                Prendre un rendez-vous
              </a>
            </div>
          </section>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
